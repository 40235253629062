/* Tailwind directives need to go first */
@tailwind base;
@tailwind components;
@tailwind utilities;



/* 1) Import Google Fonts for Poppins */
@import url('https://fonts.googleapis.com/css?family=Poppins:400,600&display=swap');

/* 2) Import local font-face definitions for MADE Outer Sans Alt */
@import url('./fonts.css');

/* 3) Variables de color de Peregrino */
:root {
    --color-azul: #2b3990;
    --color-negro: #000000;
    --color-blanco: #ffffff;
    --color-gris1: #c5c5c4;
    --color-gris2: #dcdcdc;
    --color-gris3: #ebebeb;
}

/* 4) Estilo Global */
body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif; /* Default font is Poppins */
    background-color: var(--color-blanco);
    color: var(--color-negro);
}

/* 5) Clase para titular con MADE Outer Sans Alt */
.heading-alt {
    font-family: 'MADE Outer Sans Alt', sans-serif;
}
.chat-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-button {
    background-color: var(--color-azul);
    color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
}
